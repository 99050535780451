/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Modal,
  Label,
  Input,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  FormFeedback,
  Button
} from 'reactstrap'
import { LocationModalContext } from 'pages/Customer/View'
import { GetData, PutData } from '../../apiHandler'
import { isEmpty } from 'lodash'
import { globalVariables } from 'globalVariables'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import toastr from 'toastr'
import NumberFormat from 'react-number-format'

export default function LocationModal (props) {
  const [stateBtn, setStateBtn] = useState(false)
  const [cityBtn, setCityBtn] = useState(false)
  const [primaryWD, setPrimayWD] = useState(false)
  const [secondaryWD, setSecondaryWD] = useState(false)
  const [alternateWD, setAlternateWD] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState('Select')
  const [primaryWarehouseBtn, setPrimaryWarehouseBtn] = useState(false)
  // const [secondaryWarehouseBtn, setSecondaryWarehouseBtn] = useState(false)

  // eslint-disable-next-line camelcase
  const [secondaryWD_PrimaryWarehouseBtn, setSecondaryWD_PrimaryWarehouseBtn] =
    useState(false)
  // const [
  //   secondaryWD_secondaryWarehouseBtn,
  //   setSecondaryWD_SecondaryWarehouseBtn
  // ] = useState(false)

  const [alternateWD_primaryWarehouseBtn, setAlternateWD_PrimaryWarehouseBtn] =
    useState(false)
  // const [
  //   alternateWD_secondaryWarehouseBtn,
  //   setAlternateWD_SecondaryWarehouseBtn
  // ] = useState(false)

  const [localLocationData, setLocalLocationData] = useState({})
  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState({})
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState({})
  const [WDlist, SetWDlist] = useState([])
  const [selectedPrimaryWD, setSelectedPrimaryWD] = useState({})
  const [selectedSecondaryWD, setSelectedSecondaryWD] = useState({})
  const [selectedAlternateWD, setSelectedAlternateWD] = useState({})
  const [selectedLocations, setSelectedLocations] = useState({})

  const [selectedPrimaryWarehouse, setSelectedPrimaryWarehouse] = useState({})
  const [selectedSecondaryWarehouse, setSelectedSecondaryWarehouse] = useState(
    {}
  )

  const [
    selectedSecondaryWD_PrimaryWarehouse,
    setSelectedSecondaryWD_PrimaryWarehouse
  ] = useState({})
  const [
    selectedSecondaryWD_SecondaryWarehouse,
    setSelectedSecondaryWD_SecondaryWarehouse
  ] = useState({})

  const [
    selectedAlternateWD_PrimaryWarehouse,
    setSelectedAlternateWD_PrimaryWarehouse
  ] = useState({})
  const [
    selectedAlternateWD_SecondaryWarehouse,
    setSelectedAlternateWD_SecondaryWarehouse
  ] = useState({})

  const [needValidation, setNeedValidation] = useState(false)

  const params = useParams()

  const {
    locationData,
    locationModal,
    setLocationModal,
    setLoader,
    getCustomerLocations,
    allLocations
  } = useContext(LocationModalContext)
  const countryId = globalVariables.countryId

  useEffect(() => {
    if (locationModal) fetchCommonData()
    else clearData()
    return clearData
  }, [locationModal])

  useEffect(() => {
    setLocalLocationData(locationData)
    setNeedValidation(false)
    if (!isEmpty(locationData) && !isEmpty(states)) {
      const selectedState = states?.find(
        (d) => d?.id === locationData?.shippingStateId
      )
      !isEmpty(selectedState) && setSelectedState(selectedState)
    }
  }, [locationData, locationModal, states])

  useEffect(() => {
    if (!isEmpty(locationData) && !isEmpty(states) && !isEmpty(cities)) {
      const selectedCity = cities?.find(
        (d) => d?.id === locationData?.shippingCityId
      )
      !isEmpty(selectedCity) && setSelectedCity(selectedCity)
    }
  }, [cities])

  useEffect(() => {
    if (selectedState?.id) fetchCities(selectedState?.id)
  }, [selectedState])

  useEffect(() => {
    if (!isEmpty(WDlist) && !isEmpty(locationData)) setSelectedWDs()
  }, [WDlist])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      locationName: '',
      shippingStreet: '',
      shippingZipCode: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
      contactTitle: '',
      ...localLocationData
    },
    validationSchema: Yup.object({
      locationName: Yup.string().required('Please Enter Location Name'),
      shippingStreet: Yup.string().required('Please Enter Street'),
      shippingZipCode: Yup.string().required('Please Enter Zip Code'),
      contactFirstName: Yup.string().required('Please Enter First Name'),
      contactLastName: Yup.string().required('Please Enter Last Name'),
      contactEmail: Yup.string()
        .email('Please Enter Valid Email Address')
        .required('Please Enter Email Address'),
      contactPhone: Yup.string()
        .max(10)
        .min(10)
        .required('Please Enter Mobile Number')
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          'phone number is not valid'
        ),
      contactTitle: Yup.string().required('Please Enter Title')
    }),
    onSubmit: (values, { resetForm }) => {}
  })

  const onChangeHandler = (e) => {
    setLocalLocationData((value) => ({
      ...value,
      [e?.target?.name]: e?.target?.value
    }))
  }

  const clearData = () => {
    setStates([])
    setSelectedState({})
    setCities([])
    setSelectedCity({})
    SetWDlist([])
    setSelectedPrimaryWD({})
    setSelectedSecondaryWD({})
    setSelectedAlternateWD({})
    setSelectedPrimaryWarehouse({})
    setSelectedSecondaryWarehouse({})
    setSelectedSecondaryWD_PrimaryWarehouse({})
    setSelectedSecondaryWD_SecondaryWarehouse({})
    setSelectedAlternateWD_PrimaryWarehouse({})
    setSelectedAlternateWD_SecondaryWarehouse({})
    setLocalLocationData({})
    setSelectedLocations({})

    setNeedValidation(false)
  }

  const fetchCommonData = async () => {
    await setLoader?.(true)
    await setSelectedWDs()
    await fetchStates()
    await fetchWDlist()
    await setLoader?.(false)
  }

  const fetchStates = () => {
    GetData('states?countryId=' + countryId).then((response) => {
      if (response.status === true) {
        setStates(response.result)
      }
    })
  }

  const fetchCities = (stateId) => {
    GetData(`cities?stateId=${stateId}&countryId=${countryId}`).then(
      (response) => {
        if (response.status === true) {
          setCities(response.result)
        }
      }
    )
  }

  const fetchWDlist = () => {
    GetData('admin/getWDList').then((response) => {
      if (response.status === true) {
        SetWDlist(response.result)
      }
    })
  }

  function removeBodyCss () {
    document.body.classList.add('no_padding')
  }
  function tog_large () {
    setLocationModal(!locationModal)
    removeBodyCss()
  }

  const setSelectedWDs = async () => {
    let selectedLocations = {}
    // Primary vendor selection

    const selectedPrimaryWD = await WDlist?.find?.(
      (d) => d?.vendorId === locationData?.primaryVendor?.vendorId
    )
    if (!isEmpty(selectedPrimaryWD)) {
      setSelectedPrimaryWD(selectedPrimaryWD)
      onChangeHandler({
        target: { name: 'primaryWD', value: selectedPrimaryWD }
      })
      selectedLocations = {
        ...selectedLocations,
        [selectedPrimaryWD?.vendorId]: []
      }
    }

    const selectedPrimaryWarehouse =
      await locationData?.primaryVendor?.warehouseLocations?.find?.(
        (d) => d?.isPrimaryLocation === 1
      )
    if (!isEmpty(selectedPrimaryWarehouse)) {
      setSelectedPrimaryWarehouse(selectedPrimaryWarehouse)
      onChangeHandler({
        target: {
          name: 'selectedPrimaryWarehouse',
          value: {
            ...selectedPrimaryWarehouse,
            id: selectedPrimaryWarehouse?.vendorWarehouseLocationId
          }
        }
      })
      selectedLocations = {
        ...selectedLocations,
        [selectedPrimaryWD?.vendorId]: selectedLocations?.[
          selectedPrimaryWD?.vendorId
        ]?.length
          ? [
              ...selectedLocations?.[selectedPrimaryWD?.vendorId],
              selectedPrimaryWarehouse?.vendorWarehouseLocationId
            ]
          : [selectedPrimaryWarehouse?.vendorWarehouseLocationId]
      }
    }

    const selectedSecondaryWarehouse =
      await locationData?.primaryVendor?.warehouseLocations?.find?.(
        (d) => d?.isPrimaryLocation === 0
      )
    if (!isEmpty(selectedSecondaryWarehouse)) {
      setSelectedSecondaryWarehouse(selectedSecondaryWarehouse)
      onChangeHandler({
        target: {
          name: 'selectedSecondaryWarehouse',
          value: {
            ...selectedSecondaryWarehouse,
            id: selectedSecondaryWarehouse?.vendorWarehouseLocationId
          }
        }
      })
      selectedLocations = {
        ...selectedLocations,
        [selectedPrimaryWD?.vendorId]: selectedLocations?.[
          selectedPrimaryWD?.vendorId
        ]?.length
          ? [
              ...selectedLocations?.[selectedPrimaryWD?.vendorId],
              selectedSecondaryWarehouse?.vendorWarehouseLocationId
            ]
          : [selectedSecondaryWarehouse?.vendorWarehouseLocationId]
      }
    }
    // Secondary vendor selection
    const selectedSecondaryWD = await WDlist?.find?.(
      (d) => d?.vendorId === locationData?.secondaryVendor?.vendorId
    )
    if (!isEmpty(selectedSecondaryWD)) {
      setSelectedSecondaryWD(selectedSecondaryWD)

      onChangeHandler({
        target: { name: 'secondaryWD', value: selectedSecondaryWD }
      })
    }
    const selectedSecondaryWD_PrimaryWarehouse =
      await locationData?.secondaryVendor?.warehouseLocations?.find?.(
        (d) => d?.isPrimaryLocation === 1
      )
    if (!isEmpty(selectedSecondaryWD_PrimaryWarehouse)) {
      setSelectedSecondaryWD_PrimaryWarehouse(
        selectedSecondaryWD_PrimaryWarehouse
      )

      onChangeHandler({
        target: {
          name: 'selectedSecondaryWD_PrimaryWarehouse',
          value: {
            ...selectedSecondaryWD_PrimaryWarehouse,
            id: selectedSecondaryWD_PrimaryWarehouse?.vendorWarehouseLocationId
          }
        }
      })
      selectedLocations = {
        ...selectedLocations,
        [selectedPrimaryWD?.vendorId]: selectedLocations?.[
          selectedPrimaryWD?.vendorId
        ]?.length
          ? [
              ...selectedLocations?.[selectedPrimaryWD?.vendorId],
              selectedSecondaryWD_PrimaryWarehouse?.vendorWarehouseLocationId
            ]
          : [selectedSecondaryWD_PrimaryWarehouse?.vendorWarehouseLocationId]
      }
    }
    const selectedSecondaryWD_SecondaryWarehouse =
      await locationData?.secondaryVendor?.warehouseLocations?.find?.(
        (d) => d?.isPrimaryLocation === 0
      )
    if (!isEmpty(selectedSecondaryWD_SecondaryWarehouse)) {
      setSelectedSecondaryWD_SecondaryWarehouse(
        selectedSecondaryWD_SecondaryWarehouse
      )
      onChangeHandler({
        target: {
          name: 'selectedSecondaryWD_SecondaryWarehouse',
          value: {
            ...selectedSecondaryWD_SecondaryWarehouse,
            id: selectedSecondaryWD_SecondaryWarehouse?.vendorWarehouseLocationId
          }
        }
      })
      selectedLocations = {
        ...selectedLocations,
        [selectedPrimaryWD?.vendorId]: selectedLocations?.[
          selectedPrimaryWD?.vendorId
        ]?.length
          ? [
              ...selectedLocations?.[selectedPrimaryWD?.vendorId],
              selectedSecondaryWD_SecondaryWarehouse?.vendorWarehouseLocationId
            ]
          : [selectedSecondaryWD_SecondaryWarehouse?.vendorWarehouseLocationId]
      }
    }

    // Alternate vendor selection
    const selectedAlternateWD = await WDlist?.find?.(
      (d) => d?.vendorId === locationData?.alternateVendor?.vendorId
    )
    if (!isEmpty(selectedAlternateWD)) {
      setSelectedAlternateWD(selectedAlternateWD)
      onChangeHandler({
        target: { name: 'alternateWD', value: selectedAlternateWD }
      })
    }

    const selectedAlternateWD_PrimaryWarehouse =
      await locationData?.alternateVendor?.warehouseLocations?.find?.(
        (d) => d?.isPrimaryLocation === 1
      )
    if (!isEmpty(selectedAlternateWD_PrimaryWarehouse)) {
      setSelectedAlternateWD_PrimaryWarehouse(
        selectedAlternateWD_PrimaryWarehouse
      )
      onChangeHandler({
        target: {
          name: 'selectedAlternateWD_PrimaryWarehouse',
          value: selectedAlternateWD_PrimaryWarehouse
        }
      })
      selectedLocations = {
        ...selectedLocations,
        [selectedPrimaryWD?.vendorId]: selectedLocations?.[
          selectedPrimaryWD?.vendorId
        ]?.length
          ? [
              ...selectedLocations?.[selectedPrimaryWD?.vendorId],
              selectedAlternateWD_PrimaryWarehouse?.vendorWarehouseLocationId
            ]
          : [selectedAlternateWD_PrimaryWarehouse?.vendorWarehouseLocationId]
      }
    }
    const selectedAlternateWD_SecondaryWarehouse =
      await locationData?.alternateVendor?.warehouseLocations?.find?.(
        (d) => d?.isPrimaryLocation === 0
      )
    if (!isEmpty(selectedAlternateWD_SecondaryWarehouse)) {
      setSelectedAlternateWD_SecondaryWarehouse(
        selectedAlternateWD_SecondaryWarehouse
      )
      onChangeHandler({
        target: {
          name: 'selectedAlternateWD_SecondaryWarehouse',
          value: {
            ...selectedAlternateWD_SecondaryWarehouse,
            id: selectedAlternateWD_SecondaryWarehouse?.vendorWarehouseLocationId
          }
        }
      })
      selectedLocations = {
        ...selectedLocations,
        [selectedPrimaryWD?.vendorId]: selectedLocations?.[
          selectedPrimaryWD?.vendorId
        ]?.length
          ? [
              ...selectedLocations?.[selectedPrimaryWD?.vendorId],
              selectedAlternateWD_SecondaryWarehouse?.vendorWarehouseLocationId
            ]
          : [selectedAlternateWD_SecondaryWarehouse?.vendorWarehouseLocationId]
      }
    }

    setSelectedLocations(selectedLocations)
  }

  const handleSave = async () => {
    const editData = {
      id: localLocationData?.id,
      customerId: params?.id,
      locationName: localLocationData?.locationName,
      shippingStreet: localLocationData?.shippingStreet,
      shippingStateId: localLocationData?.shippingStateId,
      shippingCityId: localLocationData?.shippingCityId,
      shippingStateName: selectedState?.name,
      shippingCityName: selectedCity?.name,
      shippingZipCode: localLocationData?.shippingZipCode,
      contactFirstName: localLocationData?.contactFirstName,
      contactLastName: localLocationData?.contactLastName,
      contactEmail: localLocationData?.contactEmail,
      contactPhone: localLocationData?.contactPhone,
      contactTitle: localLocationData?.contactTitle,
      isPrimaryLocation: Number(localLocationData?.isPrimaryLocation) || 0,
      isCorporate: Number(localLocationData?.isCorporate) || 0,
      primaryVendor: {
        vendorId: localLocationData?.primaryWD?.vendorId,
        primaryWarehouseLocationId:
          localLocationData?.selectedPrimaryWarehouse?.id,
        secondaryWarehouseLocationId:
          localLocationData?.selectedSecondaryWarehouse?.id
      },
      secondaryVendor: {
        vendorId: localLocationData?.secondaryWD?.vendorId,
        primaryWarehouseLocationId:
          localLocationData?.selectedSecondaryWD_PrimaryWarehouse?.id,
        secondaryWarehouseLocationId:
          localLocationData?.selectedSecondaryWD_SecondaryWarehouse?.id
      },
      alternateVendor: {
        vendorId: localLocationData?.alternateWD?.vendorId,
        primaryWarehouseLocationId:
          localLocationData?.selectedAlternateWD_PrimaryWarehouse?.id ||
          localLocationData?.selectedAlternateWD_PrimaryWarehouse
            ?.vendorWarehouseLocationId,
        secondaryWarehouseLocationId:
          localLocationData?.selectedAlternateWD_SecondaryWarehouse?.id
      }
    }

    const addData = {
      id: localLocationData?.id,
      customerId: params?.id,
      locationName: localLocationData?.locationName,
      shippingStreet: localLocationData?.shippingStreet,
      shippingCountryId: countryId,
      shippingStateId: localLocationData?.shippingStateId,
      shippingCityId: localLocationData?.shippingCityId,
      shippingZipCode: localLocationData?.shippingZipCode,
      billingStreet: localLocationData?.shippingStreet,
      billingCountryId: countryId,
      billingStateId: localLocationData?.shippingStateId,
      billingCityId: localLocationData?.shippingCityId,
      billingZipCode: localLocationData?.shippingZipCode,
      contactFirstName: localLocationData?.contactFirstName,
      contactLastName: localLocationData?.contactLastName,
      contactEmail: localLocationData?.contactEmail,
      contactPhone: localLocationData?.contactPhone,
      contactTitle: localLocationData?.contactTitle,
      isPrimaryLocation: Number(localLocationData?.isPrimaryLocation) || 0,
      isCorporate: Number(localLocationData?.isCorporate) || 0,
      primaryVendor: {
        vendorId: localLocationData?.primaryWD?.vendorId,
        primaryWarehouseLocationId:
          localLocationData?.selectedPrimaryWarehouse?.id,
        secondaryWarehouseLocationId:
          localLocationData?.selectedSecondaryWarehouse?.id
      },
      secondaryVendor: {
        vendorId: localLocationData?.secondaryWD?.vendorId,
        primaryWarehouseLocationId:
          localLocationData?.selectedSecondaryWD_PrimaryWarehouse?.id,
        secondaryWarehouseLocationId:
          localLocationData?.selectedSecondaryWD_SecondaryWarehouse?.id
      },
      alternateVendor: {
        vendorId: localLocationData?.alternateWD?.vendorId,
        primaryWarehouseLocationId:
          localLocationData?.selectedAlternateWD_PrimaryWarehouse?.id,
        secondaryWarehouseLocationId:
          localLocationData?.selectedAlternateWD_SecondaryWarehouse?.id
      }
    }

    setNeedValidation(true)
    validation.submitForm()

    const { isValid } = validation
    if (
      !isValid ||
      !selectedCity?.name ||
      isEmpty(selectedPrimaryWD) ||
      !selectedState?.name ||
      isEmpty(selectedPrimaryWarehouse) ||
      (!isEmpty(selectedPrimaryWarehouse) &&
        !isEmpty(selectedSecondaryWarehouse) &&
        selectedSecondaryWarehouse?.locationName ===
          selectedPrimaryWarehouse.locationName) ||
      (!isEmpty(selectedSecondaryWD_PrimaryWarehouse) &&
        !isEmpty(selectedSecondaryWD_SecondaryWarehouse) &&
        selectedSecondaryWD_SecondaryWarehouse?.locationName ===
          selectedSecondaryWD_PrimaryWarehouse.locationName) ||
      (!isEmpty(selectedAlternateWD_PrimaryWarehouse) &&
        !isEmpty(selectedAlternateWD_SecondaryWarehouse) &&
        selectedAlternateWD_SecondaryWarehouse?.locationName ===
          selectedAlternateWD_PrimaryWarehouse.locationName)
    ) {
      return
    }

    await setLoader?.(true)
    isEmpty(locationData)
      ? await PutData('admin/addCustomerLocation', addData).then((response) => {
        setLoader?.(false)
        if (response.status === true) {
          toastr.success(response.message)
          setLocationModal(false)
          getCustomerLocations()
        } else {
          toastr.error(response.message, 'Error')
        }
      })
      : await PutData('admin/editCustomerLocation', editData).then(
        (response) => {
          setLoader?.(false)
          if (response.status === true) {
            toastr.success(response.message)
            setLocationModal(false)
            getCustomerLocations()
          } else {
            toastr.error(response.message, 'Error')
          }
        }
      )
  }

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <Modal
              size="lg"
              isOpen={locationModal}
              toggle={() => {
                tog_large()
              }}
            >
              <div className="modal-header border-0">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  <b> {isEmpty(locationData) ? 'Add' : 'Edit'} Location</b>
                </h5>
                <button
                  onClick={() => {
                    setLocationModal(false)
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body location-modal-body">
                <Row>
                  <Col lg={12}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Location Name/Title*
                      </Label>
                      <Input
                        type="text"
                        name="locationName"
                        className="form-control"
                        onChange={onChangeHandler}
                        onBlur={validation.handleBlur}
                        id="formrow-firstname-Input"
                        placeholder="Enter Location Name"
                        value={localLocationData?.locationName}
                        invalid={
                          validation.touched.locationName &&
                          !!validation.errors.locationName
                        }
                      />
                    </div>
                    {validation.touched.locationName &&
                    validation.errors.locationName
                      ? (
                      <FormFeedback type="invalid">
                        {validation.errors.locationName}
                      </FormFeedback>
                        )
                      : null}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={6}>
                    <h5>Shipping Address</h5>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">Street*</Label>
                      <Input
                        type="text"
                        name="shippingStreet"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Street"
                        onChange={onChangeHandler}
                        onBlur={validation.handleBlur}
                        value={localLocationData?.shippingStreet}
                        invalid={
                          validation.touched.shippingStreet &&
                          !!validation.errors.shippingStreet
                        }
                      />
                      {validation.touched.shippingStreet &&
                      validation.errors.shippingStreet
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.shippingStreet}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">State*</Label>
                      <Dropdown
                        isOpen={stateBtn}
                        toggle={() => setStateBtn(!stateBtn)}
                        className={
                          'location-modal-select-container' +
                          (!selectedState?.name ? ' is-invalid' : '')
                        }
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>{selectedState?.name ?? selected}</span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {states?.map((d) => (
                            <DropdownItem
                              onClick={() => {
                                onChangeHandler({
                                  target: {
                                    name: 'shippingStateId',
                                    value: d?.id
                                  }
                                })
                                setSelectedState(d)
                              }}
                              key={d?.id}
                            >
                              {d?.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {needValidation && !selectedState?.name && (
                        <div type="invalid" className="invalid-feedback">
                          Please Select State
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <Row>
                        <Col lg={6}>
                          <Label htmlFor="formrow-firstname-Input">City*</Label>
                          <Dropdown
                            isOpen={cityBtn}
                            toggle={() => setCityBtn(!cityBtn)}
                            className={
                              'location-modal-select-container' +
                              (!selectedCity?.name ? ' is-invalid' : '')
                            }
                            disabled={isEmpty(selectedState)}
                          >
                            <DropdownToggle className="location-modal-select text-dark">
                              <span>{selectedCity?.name ?? selected}</span>
                              <i className=" ms-2 bx bx-caret-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {cities?.map((d) => (
                                <DropdownItem
                                  onClick={() => {
                                    onChangeHandler({
                                      target: {
                                        name: 'shippingCityId',
                                        value: d?.id
                                      }
                                    })
                                    setSelectedCity(d)
                                  }}
                                  key={d?.id}
                                >
                                  {d?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                          {needValidation && !selectedCity?.name && (
                            <div type="invalid" className="invalid-feedback">
                              Please Select City
                            </div>
                          )}
                        </Col>
                        <Col lg={6}>
                          <Label htmlFor="formrow-firstname-Input">
                            Zip Code*
                          </Label>
                          <Input
                            type="text"
                            name="shippingZipCode"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Zip Code"
                            onBlur={validation.handleBlur}
                            onChange={onChangeHandler}
                            value={localLocationData?.shippingZipCode}
                            invalid={
                              validation.touched.shippingZipCode &&
                              !!validation.errors.shippingZipCode
                            }
                          />
                          {validation.touched.shippingZipCode &&
                          validation.errors.shippingZipCode
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shippingZipCode}
                            </FormFeedback>
                              )
                            : null}
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3 mt-3">
                      <div className="form-check d-flex">
                        <Input
                          type="checkbox"
                          className="form-check-Input mt-0 me-2"
                          id="formrow-customCheck"
                          name="isPrimaryLocation"
                          onClick={(e) =>
                            onChangeHandler({
                              target: {
                                name: 'isPrimaryLocation',
                                value: !e?.target?.checked
                              }
                            })
                          }
                          checked={localLocationData?.isPrimaryLocation}
                          disabled={
                            allLocations?.filter((location) => location.isPrimaryLocation === 1)
                              .length === 1
                          }
                          // disabled={locationData?.isPrimaryLocation}
                        />
                        <Label
                          className="form-check-Label m-0"
                          style={{ lineHeight: 'normal' }}
                          htmlFor="formrow-customCheck"
                        >
                          Set as Primary Location
                        </Label>
                      </div>

                      <div className="form-check d-flex">
                        <Input
                          type="checkbox"
                          className="form-check-Input mt-0 me-2"
                          id="formrow-customCheck-corporate"
                          name="isCorporate"  
                          onClick={(e) =>
                            onChangeHandler({
                              target: {
                                name: 'isCorporate',
                                value: !e?.target?.checked
                              }
                            })
                          }
                          checked={localLocationData?.isCorporate}
                          disabled={locationData?.isCorporate}
                        />
                        <Label
                          className="form-check-Label m-0"
                          style={{ lineHeight: 'normal' }}
                          htmlFor="formrow-customCheck-corporate"
                        >
                          Set as Corporate Location
                        </Label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <h5>Contact Information</h5>
                    <div className="mb-2">
                      <Row>
                        <Col lg={6}>
                          <Label htmlFor="formrow-firstname-Input">
                            First Name*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter First Name"
                            name="contactFirstName"
                            onChange={onChangeHandler}
                            value={localLocationData?.contactFirstName}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.contactFirstName &&
                              !!validation.errors.contactFirstName
                            }
                          />
                          {validation.touched.contactFirstName &&
                          validation.errors.contactFirstName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactFirstName}
                            </FormFeedback>
                              )
                            : null}
                        </Col>
                        <Col lg={6}>
                          <Label htmlFor="formrow-firstname-Input">
                            Last Name*
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Last Name"
                            name="contactLastName"
                            onChange={onChangeHandler}
                            value={localLocationData?.contactLastName}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.contactLastName &&
                              !!validation.errors.contactLastName
                            }
                          />
                          {validation.touched.contactLastName &&
                          validation.errors.contactLastName
                            ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactLastName}
                            </FormFeedback>
                              )
                            : null}
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Email Address*
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Email Address"
                        name="contactEmail"
                        onChange={onChangeHandler}
                        value={localLocationData?.contactEmail}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.contactEmail &&
                          !!validation.errors.contactEmail
                        }
                      />
                      {validation.touched.contactEmail &&
                      validation.errors.contactEmail
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contactEmail}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Mobile Number*
                      </Label>
                      <NumberFormat
                       className="form-control" value={localLocationData?.contactPhone}
                       id="formrow-firstname-Input"
                       placeholder="Enter Mobile Number"
                       name="contactPhone" onChange={onChangeHandler}
                       displayType="input" format="(###) ###-####"
                      />
                      {/* <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Mobile Number"
                        name="contactPhone"
                        onChange={onChangeHandler}
                        value={localLocationData?.contactPhone}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.contactPhone &&
                          !!validation.errors.contactPhone
                        }
                      /> */}
                      {validation.touched.contactPhone &&
                      validation.errors.contactPhone
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contactPhone}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">Title*</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Title"
                        name="contactTitle"
                        onChange={onChangeHandler}
                        value={localLocationData?.contactTitle}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.contactTitle &&
                          !!validation.errors.contactTitle
                        }
                      />
                      {validation.touched.contactTitle &&
                      validation.errors.contactTitle
                        ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contactTitle}
                        </FormFeedback>
                          )
                        : null}
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={6}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Primary WD*
                      </Label>
                      <Dropdown
                        isOpen={primaryWD}
                        toggle={() => setPrimayWD(!primaryWD)}
                        className={
                          'location-modal-select-container' +
                          (isEmpty(selectedPrimaryWD) ? ' is-invalid' : '')
                        }
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>
                            {isEmpty(selectedPrimaryWD)
                              ? selected
                              : selectedPrimaryWD.companyName +
                                ', ' +
                                selectedPrimaryWD.vendorBillingCity +
                                ', ' +
                                selectedPrimaryWD.vendorBillingstate}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {WDlist.length ? 
                          WDlist?.map?.((d) => (
                            <DropdownItem
                              onClick={() => {
                                onChangeHandler({
                                  target: { name: 'primaryWD', value: d }
                                })
                                setSelectedPrimaryWD(d)
                                setSelectedPrimaryWarehouse({})
                                setSelectedSecondaryWarehouse({})
                                onChangeHandler({
                                  target: {
                                    name: 'selectedPrimaryWarehouse',
                                    value: {}
                                  }
                                })
                                onChangeHandler({
                                  target: {
                                    name: 'selectedSecondaryWarehouse',
                                    value: {}
                                  }
                                })
                              }}
                              key={d?.id}
                            >
                              {d?.companyName ?? ''},{' '}
                              {d?.vendorBillingCity ?? ''},{' '}
                              {d?.vendorBillingstate ?? ''}
                            </DropdownItem>
                          )) : 'No data found'}
                        </DropdownMenu>
                      </Dropdown>
                      {needValidation && isEmpty(selectedPrimaryWD) && (
                        <div type="invalid" className="invalid-feedback">
                          Please Select Primary WD
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Primary Warehouse*
                      </Label>
                      <Dropdown
                        isOpen={primaryWarehouseBtn}
                        toggle={() =>
                          setPrimaryWarehouseBtn(!primaryWarehouseBtn)
                        }
                        className={
                          'location-modal-select-container' +
                          (isEmpty(selectedPrimaryWarehouse)
                            ? ' is-invalid'
                            : '')
                        }
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>
                            {isEmpty(selectedPrimaryWarehouse)
                              ? selected
                              : selectedPrimaryWarehouse?.locationName}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(selectedPrimaryWD).length ?
                          selectedPrimaryWD?.location?.map((d) => (
                            <DropdownItem
                              onClick={() => {
                                onChangeHandler({
                                  target: {
                                    name: 'selectedPrimaryWarehouse',
                                    value: d
                                  }
                                })
                                setSelectedPrimaryWarehouse(d)
                                setSelectedLocations({
                                  ...selectedLocations,
                                  [selectedPrimaryWD?.vendorId]:
                                    selectedLocations?.[
                                      selectedPrimaryWD?.vendorId
                                    ]?.length
                                      ? [
                                          ...selectedLocations?.[
                                            selectedPrimaryWD?.vendorId
                                          ],
                                          d?.id
                                        ]
                                      : [d?.id]
                                })
                              }}
                              key={d?.id}
                            >
                              {d?.locationName}
                            </DropdownItem>
                          )) : 'No data found'}
                        </DropdownMenu>
                      </Dropdown>
                      {needValidation && isEmpty(selectedPrimaryWarehouse) && (
                        <div type="invalid" className="invalid-feedback">
                          Please Select Primary Warehouse
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* <Col lg={6}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Secondary Warehouse
                      </Label>
                      <Dropdown
                        isOpen={secondaryWarehouseBtn}
                        toggle={() =>
                          setSecondaryWarehouseBtn(!secondaryWarehouseBtn)
                        }
                        className={
                          'location-modal-select-container' +
                          (!isEmpty(selectedPrimaryWarehouse) &&
                          !isEmpty(selectedSecondaryWarehouse) &&
                          selectedSecondaryWarehouse?.locationName ===
                            selectedPrimaryWarehouse.locationName
                            ? ' is-invalid'
                            : '')
                        }
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>
                            {isEmpty(selectedSecondaryWarehouse)
                              ? selected
                              : selectedSecondaryWarehouse?.locationName}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {selectedPrimaryWD?.location?.map((d) => (
                            <DropdownItem
                              onClick={() => {
                                onChangeHandler({
                                  target: {
                                    name: 'selectedSecondaryWarehouse',
                                    value: d
                                  }
                                })
                                setSelectedSecondaryWarehouse(d)
                                setSelectedLocations({
                                  ...selectedLocations,
                                  [selectedPrimaryWD?.vendorId]:
                                    selectedLocations?.[
                                      selectedPrimaryWD?.vendorId
                                    ]?.length
                                      ? [
                                          ...selectedLocations?.[
                                            selectedPrimaryWD?.vendorId
                                          ],
                                          d?.id
                                        ]
                                      : [d?.id]
                                })
                              }}
                              key={d?.id}
                            >
                              {d?.locationName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      {
                        <div type="invalid" className="invalid-feedback">
                          Primary Warehouse & Secondary Warehouse must be
                          different
                        </div>
                      }
                    </div>
                  </Col> */}
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={6}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Secondary WD
                      </Label>
                      <Dropdown
                        isOpen={secondaryWD}
                        toggle={() => setSecondaryWD(!secondaryWD)}
                        className="location-modal-select-container"
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>
                            {isEmpty(selectedSecondaryWD)
                              ? selected
                              : selectedSecondaryWD.companyName +
                                ', ' +
                                selectedSecondaryWD.vendorBillingCity +
                                ', ' +
                                selectedSecondaryWD.vendorBillingstate}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {WDlist.length ?
                          WDlist?.map((d) => (
                            <DropdownItem
                              onClick={() => {
                                onChangeHandler({
                                  target: { name: 'secondaryWD', value: d }
                                })
                                setSelectedSecondaryWD(d)
                                setSelectedSecondaryWD_PrimaryWarehouse({})
                                setSelectedSecondaryWD_SecondaryWarehouse({})
                                onChangeHandler({
                                  target: {
                                    name: 'selectedSecondaryWD_PrimaryWarehouse',
                                    value: {}
                                  }
                                })
                                onChangeHandler({
                                  target: {
                                    name: 'selectedSecondaryWD_SecondaryWarehouse',
                                    value: {}
                                  }
                                })
                              }}
                              key={d?.id}
                            >
                              {d?.companyName ?? ''},{' '}
                              {d?.vendorBillingCity ?? ''},{' '}
                              {d?.vendorBillingstate ?? ''}
                            </DropdownItem>
                          )) : 'No data found'}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                  {!isEmpty(selectedSecondaryWD) && (
                    <Col lg={6}>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => {
                          setSelectedSecondaryWD({})
                          setSelectedSecondaryWD_PrimaryWarehouse({})
                          setSelectedSecondaryWD_SecondaryWarehouse({})
                          onChangeHandler({
                            target: { name: 'secondaryWD', value: {} }
                          })
                          onChangeHandler({
                            target: {
                              name: 'selectedSecondaryWD_PrimaryWarehouse',
                              value: {}
                            }
                          })
                          onChangeHandler({
                            target: {
                              name: 'selectedSecondaryWD_SecondaryWarehouse',
                              value: {}
                            }
                          })
                        }}
                      >
                        Clear Field
                      </Button>
                    </Col>
                  )}
                </Row>
                {isEmpty(selectedSecondaryWD)
                  ? (
                  <></>
                    )
                  : (
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Primary Warehouse
                        </Label>
                        <Dropdown
                          isOpen={secondaryWD_PrimaryWarehouseBtn}
                          toggle={() =>
                            setSecondaryWD_PrimaryWarehouseBtn(
                              !secondaryWD_PrimaryWarehouseBtn
                            )
                          }
                          className="location-modal-select-container"
                        >
                          <DropdownToggle className="location-modal-select text-dark">
                            <span>
                              {isEmpty(selectedSecondaryWD_PrimaryWarehouse)
                                ? selected
                                : selectedSecondaryWD_PrimaryWarehouse?.locationName}
                            </span>
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.keys(selectedSecondaryWD).length ?
                            selectedSecondaryWD?.location?.map((d) => (
                              <DropdownItem
                                onClick={() => {
                                  onChangeHandler({
                                    target: {
                                      name: 'selectedSecondaryWD_PrimaryWarehouse',
                                      value: d
                                    }
                                  })
                                  setSelectedSecondaryWD_PrimaryWarehouse(d)
                                  setSelectedLocations({
                                    ...selectedLocations,
                                    [selectedSecondaryWD?.vendorId]:
                                      selectedLocations?.[
                                        selectedSecondaryWD?.vendorId
                                      ]?.length
                                        ? [
                                            ...selectedLocations?.[
                                              selectedSecondaryWD?.vendorId
                                            ],
                                            d?.id
                                          ]
                                        : [d?.id]
                                  })
                                }}
                                key={d?.id}
                              >
                                {d?.locationName}
                              </DropdownItem>
                            )): 'No data found'}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                    {/* <Col lg={6}>
                      <div className="mb-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Secondary Warehouse
                        </Label>
                        <Dropdown
                          isOpen={secondaryWD_secondaryWarehouseBtn}
                          toggle={() =>
                            setSecondaryWD_SecondaryWarehouseBtn(
                              !secondaryWD_secondaryWarehouseBtn
                            )
                          }
                          className={
                            'location-modal-select-container' +
                            (!isEmpty(selectedSecondaryWD_PrimaryWarehouse) &&
                            !isEmpty(selectedSecondaryWD_SecondaryWarehouse) &&
                            selectedSecondaryWD_SecondaryWarehouse?.locationName ===
                              selectedSecondaryWD_PrimaryWarehouse.locationName
                              ? ' is-invalid'
                              : '')
                          }
                        >
                          <DropdownToggle className="location-modal-select text-dark">
                            <span>
                              {isEmpty(selectedSecondaryWD_SecondaryWarehouse)
                                ? selected
                                : selectedSecondaryWD_SecondaryWarehouse?.locationName}
                            </span>
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {selectedSecondaryWD?.location?.map((d) => (
                              <DropdownItem
                                onClick={() => {
                                  onChangeHandler({
                                    target: {
                                      name: 'selectedSecondaryWD_SecondaryWarehouse',
                                      value: d
                                    }
                                  })
                                  setSelectedSecondaryWD_SecondaryWarehouse(d)
                                  setSelectedLocations({
                                    ...selectedLocations,
                                    [selectedSecondaryWD?.vendorId]:
                                      selectedLocations?.[
                                        selectedSecondaryWD?.vendorId
                                      ]?.length
                                        ? [
                                            ...selectedLocations?.[
                                              selectedSecondaryWD?.vendorId
                                            ],
                                            d?.id
                                          ]
                                        : [d?.id]
                                  })
                                }}
                                key={d?.id}
                              >
                                {d?.locationName}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        {
                          <div type="invalid" className="invalid-feedback">
                            Primary Warehouse & Secondary Warehouse must be
                            different
                          </div>
                        }
                      </div>
                    </Col> */}
                  </Row>
                    )}

                <hr></hr>
                <Row>
                  <Col lg={6}>
                    <div className="mb-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Alternate WD
                      </Label>
                      <Dropdown
                        isOpen={alternateWD}
                        toggle={() => setAlternateWD(!alternateWD)}
                        className="location-modal-select-container"
                      >
                        <DropdownToggle className="location-modal-select text-dark">
                          <span>
                            {isEmpty(selectedAlternateWD)
                              ? selected
                              : selectedAlternateWD.companyName +
                                ', ' +
                                selectedAlternateWD.vendorBillingCity +
                                ', ' +
                                selectedAlternateWD.vendorBillingstate}
                          </span>
                          <i className=" ms-2 bx bx-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {WDlist.length ?
                          WDlist?.map((d) => (
                            <DropdownItem
                              onClick={() => {
                                onChangeHandler({
                                  target: { name: 'alternateWD', value: d }
                                })
                                setSelectedAlternateWD(d)
                                setSelectedAlternateWD_PrimaryWarehouse({})
                                setSelectedAlternateWD_SecondaryWarehouse({})
                                onChangeHandler({
                                  target: {
                                    name: 'selectedAlternateWD_PrimaryWarehouse',
                                    value: {}
                                  }
                                })
                                onChangeHandler({
                                  target: {
                                    name: 'selectedAlternateWD_SecondaryWarehouse',
                                    value: {}
                                  }
                                })
                              }}
                              key={d?.id}
                            >
                              {d?.companyName ?? ''},{' '}
                              {d?.vendorBillingCity ?? ''},{' '}
                              {d?.vendorBillingstate ?? ''}
                            </DropdownItem>
                          )) : 'No data found'}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                  {!isEmpty(selectedAlternateWD) && (
                    <Col lg={6}>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => {
                          setSelectedAlternateWD({})
                          setSelectedAlternateWD_PrimaryWarehouse({})
                          setSelectedAlternateWD_SecondaryWarehouse({})
                          onChangeHandler({
                            target: { name: 'alternateWD', value: {} }
                          })
                          onChangeHandler({
                            target: {
                              name: 'selectedAlternateWD_PrimaryWarehouse',
                              value: {}
                            }
                          })
                          onChangeHandler({
                            target: {
                              name: 'selectedAlternateWD_SecondaryWarehouse',
                              value: {}
                            }
                          })
                        }}
                      >
                        Clear Field
                      </Button>
                    </Col>
                  )}
                </Row>
                {isEmpty(selectedAlternateWD)
                  ? (
                  <></>
                    )
                  : (
                  <Row>
                    <Col lg={6}>
                      <div className="mb-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Primary Warehouse
                        </Label>
                        <Dropdown
                          isOpen={alternateWD_primaryWarehouseBtn}
                          toggle={() =>
                            setAlternateWD_PrimaryWarehouseBtn(
                              !alternateWD_primaryWarehouseBtn
                            )
                          }
                          className="location-modal-select-container"
                        >
                          <DropdownToggle className="location-modal-select text-dark">
                            <span>
                              {isEmpty(selectedAlternateWD_PrimaryWarehouse)
                                ? selected
                                : selectedAlternateWD_PrimaryWarehouse?.locationName}
                            </span>
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {selectedAlternateWD?.location?.map((d) => (
                              <DropdownItem
                                onClick={() => {
                                  onChangeHandler({
                                    target: {
                                      name: 'selectedAlternateWD_PrimaryWarehouse',
                                      value: d
                                    }
                                  })
                                  setSelectedAlternateWD_PrimaryWarehouse(d)
                                  setSelectedLocations({
                                    ...selectedLocations,
                                    [selectedAlternateWD?.vendorId]:
                                      selectedLocations?.[
                                        selectedAlternateWD?.vendorId
                                      ]?.length
                                        ? [
                                            ...selectedLocations?.[
                                              selectedAlternateWD?.vendorId
                                            ],
                                            d?.id
                                          ]
                                        : [d?.id]
                                  })
                                }}
                                key={d?.id}
                              >
                                {d?.locationName}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      {needValidation && !selectedPrimaryWarehouse?.name && (
                        <div type="invalid" className="invalid-feedback">
                          Please Select Primary Warehouse
                        </div>
                      )}
                    </Col>
                    {/* <Col lg={6}>
                      <div className="mb-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Secondary Warehouse
                        </Label>
                        <Dropdown
                          isOpen={alternateWD_secondaryWarehouseBtn}
                          toggle={() =>
                            setAlternateWD_SecondaryWarehouseBtn(
                              !alternateWD_secondaryWarehouseBtn
                            )
                          }
                          className={
                            'location-modal-select-container' +
                            (!isEmpty(selectedAlternateWD_PrimaryWarehouse) &&
                            !isEmpty(selectedAlternateWD_SecondaryWarehouse) &&
                            selectedAlternateWD_SecondaryWarehouse?.locationName ===
                              selectedAlternateWD_PrimaryWarehouse.locationName
                              ? ' is-invalid'
                              : '')
                          }
                        >
                          <DropdownToggle className="location-modal-select text-dark">
                            <span>
                              {isEmpty(selectedAlternateWD_SecondaryWarehouse)
                                ? selected
                                : selectedAlternateWD_SecondaryWarehouse?.locationName}
                            </span>
                            <i className=" ms-2 bx bx-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {selectedAlternateWD?.location?.map((d) => (
                              <DropdownItem
                                onClick={() => {
                                  onChangeHandler({
                                    target: {
                                      name: 'selectedAlternateWD_SecondaryWarehouse',
                                      value: d
                                    }
                                  })
                                  setSelectedAlternateWD_SecondaryWarehouse(d)
                                  setSelectedLocations({
                                    ...selectedLocations,
                                    [selectedAlternateWD?.vendorId]:
                                      selectedLocations?.[
                                        selectedAlternateWD?.vendorId
                                      ]?.length
                                        ? [
                                            ...selectedLocations?.[
                                              selectedAlternateWD?.vendorId
                                            ],
                                            d?.id
                                          ]
                                        : [d?.id]
                                  })
                                }}
                                key={d?.id}
                              >
                                {d?.locationName}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        {
                          <div type="invalid" className="invalid-feedback">
                            Primary Warehouse & Secondary Warehouse must be
                            different
                          </div>
                        }
                      </div>
                    </Col> */}
                  </Row>
                    )}

                <Row>
                  <Col lg={12}>
                    <div className="d-flex justify-content-end">
                      <button
                        className="location-modal-cancel-btn"
                        onClick={tog_large}
                      >
                        Cancel
                      </button>
                      <button
                        className="location-modal-save-btn"
                        onClick={handleSave}
                      >
                        {' '}
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  )
}
